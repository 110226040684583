<!-- 页面模板 -->
<template>
    <div class="pages">
        <el-form :model="searchForm" inline ref="ruleForm" label-width="100px">
            <el-form-item label="名称" prop="name">
                <el-input v-model="searchForm.name"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
        </el-form>

        <div>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="editAdd('add')">添加</el-button>
            <el-button type="danger" icon="el-icon-delete-solid" @click="delAll">批量删除</el-button>
        </div>

        <page-table
            v-loading="loading"
            :data="tableData"
            :columns="columns"
            :currentPage="searchForm.currentPage"
            :pageSize="searchForm.pageSize"
            :total="searchForm.total"
            @on-current-click="currentPageList"
            @on-size-change="sizeChange"
            @on-selection-change="selectionChange"
        >
            <template slot-scope="scope">
                <el-button type="text" icon="el-icon-s-platform" @click="editAdd('view', scope.row)">查看</el-button>
                <el-button type="text" icon="el-icon-edit" @click="editAdd('edit', scope.row)">编辑</el-button>
                <el-button type="text" icon="el-icon-delete" @click="del(scope.row)">删除</el-button>
            </template>
        </page-table>

        <!-- 查看/编辑 弹窗 -->
        <edit-dialog ref="editDialogRef"></edit-dialog>
    </div>
</template>

<script>
import EditDialog from './edit-dialog.vue';

export default {
    name: 'templates',
    components: { EditDialog },
    data() {
        return {
            // 搜索条件
            searchForm: {
                // 业务字段
                name: '',

                // 固定字段
                currentPage: 1, // 当前分页
                pageSize: 10, // 每页数量
                total: 0 // 总数
            },
            // 表格加载
            loading: false,
            // 数据列表
            tableData: [],
            // table字段配置
            columns: [
                {
                    type: 'selection',
                    fixed: true
                },
                {
                    type: 'index',
                    fixed: true
                },
                {
                    label: '名称',
                    prop: 'name',
                    sortable: true // 允许排序
                }
            ],
            // 已勾选列表
            selectionList: []
        };
    },
    created() {
        this.getList();
    },
    mounted() {},
    methods: {
        // 重置搜索列表
        resetList() {
            this.searchForm = {
                // 业务字段
                name: '',

                // 固定字段
                currentPage: 1, // 当前分页
                pageSize: 10, // 每页数量
                total: 0 // 总数
            };
            this.getList();
        },
        // 条件搜索列表
        searchList() {
            this.searchForm.currentPage = 1;
            this.getList();
        },
        // 分页数量改变
        sizeChange() {},
        // 列表翻页
        currentPageList(e) {
            if (e.type == 'prev') this.searchForm.currentPage -= 1;
            else if (e.type == 'next') this.searchForm.currentPage += 1;
            else if (e.type == 'size') {
                this.searchForm.currentPage = 1;
                this.searchForm.pageSize = e;
            } else this.searchForm.currentPage = e.current;
            this.getList();
        },
        // 获取列表
        getList() {
            // this.loading = true;
            // getLists(this.searchForm)
            //     .then(res => {
            //         this.loading = false;
            //         const { code = 0, data = '', msg = '' } = res;
            //         if (code) {
            //             this.tableData = data;
            //         } else {
            //             this.$message.error(msg);
            //         }
            //     })
            //     .catch(err => {
            //         this.loading = false;
            //         this.$message.error(err);
            //     });
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        // 编辑
        editAdd(type = 'view', val = {}) {
            this.$refs.editDialogRef.show(val, type);
        },
        // 删除
        del(val) {
            this.$confirm('确认删除此数据？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        delAll(val) {
            if (this.selectionList.length == 0) return this.$message.error('请选择要删除的数据');
            this.$confirm(`确认删除 ${this.selectionList.length} 条数据？`)
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        }
    }
};
</script>

<style lang="less" scoped></style>
