<!-- 查看/编辑 弹窗 -->
<template>
    <div class="">
        <el-dialog :title="title" :visible.sync="dialogVisible" width="50%">
            <div class="dialog-content">
                <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="活动名称" prop="name">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="活动名称" prop="name">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button v-if="dialogType != 'add'" :loading="loading" type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            dialogType: 'view', // 弹窗类型: view 查看 | edit 编辑 | add 添加
            dialogVisible: false,
            loading: false,
            title: '',
            form: {
                name: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ]
            }
        };
    },
    created() {},
    mounted() {},
    methods: {
        // 显示弹窗
        show(data, type) {
            this.dialogType = type;
            if (type == 'view') {
                this.title = '查看';
                this.$set(this, 'form', data);
            } else if (type == 'edit') {
                this.title = '编辑';
                this.$set(this, 'form', data);
            } else if (type == 'add') {
                this.title = '添加';
                try {
                    this.$refs.ruleForm.resetFields();
                } catch {
                    this.form = {
                        name: ''
                    };
                }
            }
            this.dialogVisible = true;
        },
        // 提交按钮
        submit() {
            this.loading = true;
            this.$refs.ruleForm.validate(valid => {
                this.loading = false;
                if (valid) {
                    this.$emit('on-submit', this.form);
                    this.dialogVisible = false;
                }
            });
        }
    }
};
</script>

<style lang="less" scoped></style>
